import { ApolloError } from '@apollo/client';
import { createContext } from 'react';

import { GetOrderSummaryQuery as GetOrderSummary } from '__generated__/graphql';

import { CheckoutStep, Product } from './helpers';
import { ErrorBag } from '../../common/helpers';
import { PaymentMethodsInfo } from '../../common/usePaymentMethods';
import { PersonalisationInfo } from './usePersonalisationInfo';
import { StateContextValue } from './StateContext';
import useQuantities from './useQuantities';

export interface CheckoutContextValue extends Partial<StateContextValue>, ReturnType<typeof useQuantities> {
  standAloneProducts: Product[];
  personalisation: PersonalisationInfo;
  allowIndividuals: boolean;
  allowCreateTeam: boolean;
  allowJoinTeam: boolean;
  requireInvoiceDetails: boolean;
  validators: {
    [step in CheckoutStep]: {
      valid: boolean;
      errors: ErrorBag;
      validating: string[];
      updateCustomErrors: (errors: ErrorBag, prefix: string) => void;
      updateValidating: (validating: string[], prefix: string) => void;
    };
  };
  error?: ApolloError;
  paymentMethods?: PaymentMethodsInfo;
  orderSummary?: GetOrderSummary['orderSummary'];
}

const initialContext = {} as CheckoutContextValue;

export default createContext<CheckoutContextValue>(initialContext);
