import { useMemo } from 'react';

import {
  BusinessRegistrationInput, CompleteCheckoutInput, CreateRegistrationInput, CreateUpgradeInput,

} from '__generated__/graphql';
import {
  Event, Product, ProductVariant, Promotion, Ticket, TicketCategory, TimeSlot, getKeyedSellables,
} from './helpers';

interface Registration {
  upgrades: {
    product: { id: string; };
  }[];
}

export interface Quantities {
  promotions: { [promotionId: string]: number; };
  ticketCategories: { [ticketCategoryId: string]: number; };
  tickets: { [ticketId: string]: number; };
  timeSlots: { [promotionId: string]: { [timeSlotId: string]: number; }; };
  products: { [productId: string]: number; };
  productVariants: { [promotionId: string]: { [productVariantId: string]: number; }; };
  purchases: { [promotionId: string]: number; };
}

export interface Availability {
  promotions: { [promotionId: string]: number; };
  timeSlots: { [promotionId: string]: { [timeSlotId: string]: number; }; };
  productVariants: { [promotionId: string]: { [productVariantId: string]: number; }; };
}

const sumReducer = (sum: number, count: number) => sum + count;

// The overall maximum number of purchases per form.
const maxPurchases = 500;

interface UseQuantitiesProps {
  form: CompleteCheckoutInput;
  event: Event;
}

const useQuantities = ({ form, event }: UseQuantitiesProps) => {
  const registrations = form.registrations.create;
  const businessRegistrations = form.registrations.business;
  const standAloneUpgrades = form.registrations.stand_alone_upgrades;

  return useMemo(
    () => {
      const quantities = calculateQuantities({
        registrations,
        businessRegistrations,
        standAloneUpgrades,
        ticketCategories: event.ticket_categories,
        products: event.products_for_sale,
      });

      const availability = calculateAvailability({
        quantities,
        ticketCategories: event.ticket_categories,
        products: event.products_for_sale,
        // Invitation codes can be valid for a maximum number of registrations.
        maxRegistrations: event.invitation_code?.availability,
      });

      return {
        quantities,
        availability,
      };
    },
    [registrations, businessRegistrations, standAloneUpgrades, event],
  );
};

interface CalculateQuantitiesProps {
  registrations?: CreateRegistrationInput[];
  businessRegistrations?: BusinessRegistrationInput[];
  standAloneUpgrades?: CreateUpgradeInput[];
  ticketCategories?: TicketCategory[];
  products?: Product[];
}

export const calculateQuantities = ({
  registrations = [], businessRegistrations = [], standAloneUpgrades = [], ticketCategories = [], products = [],
}: CalculateQuantitiesProps = {}) => {
  const timeSlotUnits = {} as {
    [promotionId: string]: { [timeSlotId: string]: number; };
  };

  const quantities: Quantities = {
    promotions: {},
    ticketCategories: {},
    tickets: {},
    timeSlots: {},
    products: {},
    productVariants: {},
    purchases: {},
  };

  const countUnits = (promotionId: string, timeSlotId?: string, quantity: number = 1) => {
    quantities.purchases[promotionId] = (quantities.purchases[promotionId] || 0) + quantity;

    if (timeSlotId) {
      timeSlotUnits[promotionId] = timeSlotUnits[promotionId] || {};

      timeSlotUnits[promotionId][timeSlotId] = (
        (timeSlotUnits[promotionId][timeSlotId] || 0) + quantity
      );
    }
  };

  registrations.forEach((registration) => {
    countUnits(registration.purchase.promotion.id, registration.time_slot?.id);
  });

  /** Flattened list of all upgrades */
  const allUpgrades = [
    ...registrations.reduce((upgrades, registration) => [...upgrades, ...registration.upgrades], []),
    ...standAloneUpgrades,
  ];

  const tickets = ticketCategories.reduce((tickets, ticketCategory) => [
    ...tickets, ...ticketCategory.tickets_for_sale,
  ], [] as Ticket[]);
  const keyedTickets = getKeyedSellables(tickets);

  businessRegistrations.forEach((registration) => {
    const ticket = keyedTickets[registration.promotion.id];

    if (ticket) {
      // Ticket might not exist anymore
      const quantity = ticket.units * registration.quantity;
      countUnits(registration.promotion.id, registration.time_slot?.id, quantity);

      registration.upgrades.forEach((upgrade) => {
        for (let i = 0; i < quantity; i++) {
          allUpgrades.push(upgrade);
        }
      });
    }
  });

  allUpgrades.forEach((upgrade) => {
    const promotionId = upgrade.purchase.promotion.id;
    const productVariantId = upgrade.product_variant?.id;

    quantities.purchases[promotionId] = (quantities.purchases[promotionId] || 0) + 1;

    if (productVariantId) {
      quantities.productVariants[promotionId] = quantities.productVariants[promotionId] || {};

      quantities.productVariants[promotionId][productVariantId] = (
        (quantities.productVariants[promotionId][productVariantId] || 0) + 1
      );
    }
  });

  ticketCategories.forEach((ticketCategory) => {
    quantities.ticketCategories[ticketCategory.id] = 0;

    ticketCategory.tickets_for_sale.forEach((ticket) => {
      quantities.tickets[ticket.id] = 0;

      ticket.promotions_for_sale.forEach((promotion) => {
        // Group tickets add multiple purchases, calculate the original quantity.
        const quantity = Math.floor((quantities.purchases[promotion.id] || 0) / ticket.units);

        quantities.promotions[promotion.id] = quantity;
        quantities.tickets[ticket.id] += quantity;

        ticket.upcoming_time_slots.forEach((timeSlot) => {
          quantities.timeSlots[promotion.id] = quantities.timeSlots[promotion.id] || {};

          quantities.timeSlots[promotion.id][timeSlot.id] = Math.floor(
            (timeSlotUnits[promotion.id]?.[timeSlot.id] || 0) / ticket.units,
          );
        });

        quantities.ticketCategories[ticketCategory.id] += quantity * ticket.units;
      });
    });
  });

  products.forEach((product) => {
    quantities.products[product.id] = 0;

    product.promotions_for_sale.forEach((promotion) => {
      const quantity = quantities.purchases[promotion.id] || 0;

      quantities.promotions[promotion.id] = quantity;
      quantities.products[product.id] += quantity;

      product.active_product_variants.forEach((productVariant) => {
        quantities.productVariants[promotion.id] = quantities.productVariants[promotion.id] || {};

        quantities.productVariants[promotion.id][productVariant.id] = (
          quantities.productVariants[promotion.id][productVariant.id] || 0
        );
      });
    });
  });

  return quantities;
};

interface CalculateAvailabilityProps {
  quantities: Quantities;
  ticketCategories?: TicketCategory[];
  products?: Product[];
  maxRegistrations?: number | null;
}

export const calculateAvailability = ({
  quantities, ticketCategories = [], products = [], maxRegistrations = null,
}: CalculateAvailabilityProps) => {
  /** Total number of purchases. */
  const purchaseCount: number = Object.values(quantities.purchases).reduce(sumReducer, 0);

  /** Shared capacity over all purchases to not reach the server limit. */
  const purchasesLeft = Math.max(0, maxPurchases - purchaseCount);

  const getTicketQuantity = (ticketId?: string) => {
    if (ticketId) {
      return quantities.tickets[ticketId] || 0;
    }

    return Object.values(quantities.tickets).reduce(sumReducer, 0);
  };

  const getTimeSlotQuantity = (timeSlotId: string, promotionId?: string) => {
    if (!promotionId) {
      let quantity = 0;

      Object.keys(quantities.timeSlots).forEach((promotionId) => {
        quantity += quantities.timeSlots[promotionId][timeSlotId] || 0;
      });

      return quantity;
    }

    const timeSlotQuantities = quantities.timeSlots[promotionId];

    return timeSlotQuantities ? timeSlotQuantities[timeSlotId] || 0 : 0;
  };

  const getProductQuantity = (productId?: string) => {
    if (productId) {
      return quantities.products[productId] || 0;
    }

    return Object.values(quantities.products).reduce(sumReducer, 0);
  };

  const getProductVariantQuantity = (productVariantId: string, promotionId?: string) => {
    if (!promotionId) {
      let quantity = 0;

      Object.keys(quantities.productVariants).forEach((promotionId) => {
        quantity += quantities.productVariants[promotionId][productVariantId] || 0;
      });

      return quantity;
    }

    const productVariantQuantities = quantities.productVariants[promotionId];

    return productVariantQuantities ? productVariantQuantities[productVariantId] || 0 : 0;
  };

  const getPromotionQuantity = (promotionId?: string) => {
    if (promotionId) {
      return quantities.promotions[promotionId] || 0;
    }

    return Object.values(quantities.promotions).reduce(sumReducer, 0);
  };

  const getTicketAvailability = (
    ticketCategory: TicketCategory,
    ticket: Ticket,
    promotion: Promotion,
    timeSlot?: TimeSlot,
  ): number => {
    const ticketPurchasesLeft = ticketCategory.current_max_per_order - quantities.ticketCategories[ticketCategory.id];

    const constraints = [
      // Shared capacity over all purchases to not reach the server limit.
      Math.floor(purchasesLeft / ticket.units),
      // Shared capacity over ticket categories.
      Math.floor(ticketPurchasesLeft / ticket.units),
    ];

    // For example, maximum 1 registration when using personal invitations.
    if (maxRegistrations !== null) {
      constraints.push(maxRegistrations - getTicketQuantity());
    }

    if (ticket.current_max_per_order !== null) {
      // Divide by units to account for group tickets.
      // E.g., if a ticket is sold per 8 and there is a maximum of 10, you can buy at most 1.
      constraints.push(
        Math.floor(ticket.current_max_per_order / ticket.units) - getTicketQuantity(ticket.id),
      );
    }

    if (promotion.current_max_per_order !== null) {
      constraints.push(
        Math.floor(promotion.current_max_per_order / ticket.units) - getPromotionQuantity(promotion.id),
      );
    }

    if (timeSlot && timeSlot.current_max_per_order !== null) {
      constraints.push(
        Math.floor(timeSlot.current_max_per_order / ticket.units) - getTimeSlotQuantity(timeSlot.id),
      );
    }

    return Math.max(Math.min(...constraints), 0);
  };

  const getProductAvailability = (
    product: Product,
    promotion: Promotion,
    productVariant?: ProductVariant,
  ): number => {
    const constraints = [purchasesLeft];

    if (product.current_max_per_order !== null) {
      constraints.push(product.current_max_per_order - getProductQuantity(product.id));
    }

    if (promotion.current_max_per_order !== null) {
      constraints.push(promotion.current_max_per_order - getPromotionQuantity(promotion.id));
    }

    if (productVariant && productVariant.current_max_per_order !== null) {
      constraints.push(
        productVariant.current_max_per_order - getProductVariantQuantity(productVariant.id),
      );
    }

    return Math.max(Math.min(...constraints), 0);
  };

  const availability: Availability = {
    promotions: {},
    timeSlots: {},
    productVariants: {},
  };

  ticketCategories.forEach((ticketCategory) => {
    ticketCategory.tickets_for_sale.forEach((ticket) => {
      ticket.promotions_for_sale.forEach((promotion) => {
        availability.promotions[promotion.id] = getTicketAvailability(ticketCategory, ticket, promotion);

        availability.timeSlots[promotion.id] = {};

        ticket.upcoming_time_slots.forEach((timeSlot) => {
          availability.timeSlots[promotion.id][timeSlot.id] = getTicketAvailability(
            ticketCategory, ticket, promotion, timeSlot,
          );
        });
      });
    });
  });

  products.forEach((product) => {
    product.promotions_for_sale.forEach((promotion) => {
      availability.promotions[promotion.id] = getProductAvailability(product, promotion);

      availability.productVariants[promotion.id] = {};

      product.active_product_variants.forEach((productVariant) => {
        availability.productVariants[promotion.id][productVariant.id] = (
          getProductAvailability(product, promotion, productVariant)
        );
      });
    });
  });

  return availability;
};

interface CalculateUpgradeAvailabilityProps {
  /** The quantities object that contains the currently selected upgrades. * */
  quantities: Quantities;
  /** The quantities object that contains remaining availability of all products. * */
  availability: Availability;
  /** The list of products that can be chosen. * */
  products?: Product[];
  /** The existing registration that is being upgraded, if applicable. * */
  registration?: Pick<Registration, 'upgrades'>;
  units?: number;
}

/**
 * Applies the products' max_per_ticket restrictions to the availability object for a given registration.
 */
export const calculateUpgradeAvailability = ({
  quantities, availability, products = [], registration, units = 1,
}: CalculateUpgradeAvailabilityProps) => {
  const result: Availability = {
    promotions: {},
    productVariants: {},
    timeSlots: {},
  };

  products.forEach((product) => {
    const maxPerTicket = product.max_per_ticket !== null ? product.max_per_ticket * units : null;
    const productQuantity = (registration?.upgrades.filter((upgrade) => upgrade.product.id === product.id).length || 0)
      + quantities.products[product.id];

    product.promotions_for_sale.forEach((promotion) => {
      result.promotions[promotion.id] = maxPerTicket !== null
        ? Math.max(0, Math.min(availability.promotions[promotion.id], maxPerTicket - productQuantity))
        : availability.promotions[promotion.id];

      result.productVariants[promotion.id] = {};

      product.active_product_variants.forEach((productVariant) => {
        result.productVariants[promotion.id][productVariant.id] = maxPerTicket !== null
          ? Math.max(
            0,
            Math.min(availability.productVariants[promotion.id][productVariant.id], maxPerTicket - productQuantity),
          )
          : availability.productVariants[promotion.id][productVariant.id];
      });
    });
  });

  return result;
};

export default useQuantities;
