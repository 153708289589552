import intersection from 'lodash/intersection';
import keyBy from 'lodash/keyBy';
import times from 'lodash/times';

import {
  CreateRegistrationInput,
  CreateUpgradeInput,
  GetCheckoutSummaryQuery as GetCheckoutSummary,
  GetEventQuery as GetEvent,
  ParticipantAttributes,
  SavePurchaseInput,
} from '__generated__/graphql';

import { Charity } from '../../common/Fundraising/CharityPicker';
import { NestedTouchState } from '../../common/useTouchState';
import { sortedParticipantAttributes } from '../../common/helpers';

export type Event = GetEvent['event'];
export type TicketCategory = Event['ticket_categories'][0];
export type Ticket = TicketCategory['tickets_for_sale'][0];
export type TimeSlot = Ticket['upcoming_time_slots'][0];
export type Product = Event['products_for_sale'][0];
export type ProductVariant = Product['active_product_variants'][0];
export type Sellable = Ticket | Product;
export type Promotion = Ticket['promotions_for_sale'][0];

export type ParticipantField = Event['enabled_participant_fields'][0];
export type ParticipantFieldChoice = ParticipantField['enabled_choices'][0];

export type Purchase = SavePurchaseInput;

export type CouponCode = GetCheckoutSummary['checkoutSummary']['coupon_code'];

export enum CheckoutStep {
  Tickets = 'tickets',
  Personalisation = 'personalisation',
  Extras = 'extras',
  Team = 'team',
  Fundraising = 'fundraising',
  Details = 'details',
  Payment = 'payment',
}

export const SuccessStep = 'thank-you';

export interface CheckoutParams {
  eventId?: string;
  step?: CheckoutStep | typeof SuccessStep;
}

export interface Session {
  activeRegistration: number;
  invoiceDetails: boolean;
  agreedToTerms: boolean;
  charities: { [charityId: string]: Charity; };
  donate: { [productId: string]: boolean; };
  touched: NestedTouchState;
  queueToken: string | null;
  showTimers: boolean;
  featureFlags?: string[];
}

export interface Queue {
  id: string;
  starting_at?: string | null;
  token_url: string;
}

export function getKeyedSellables<T extends Sellable>(sellables: T[]) {
  const result: { [promotionId: string]: T } = {};

  sellables.forEach((sellable) => {
    sellable.promotions_for_sale.forEach((promotion) => {
      result[promotion.id] = sellable;
    });
  });

  return result;
}

export function getKeyedTimeSlots(tickets: Ticket[]) {
  const result: { [timeSlotId: string]: TimeSlot } = {};

  tickets.forEach((ticket) => {
    ticket.upcoming_time_slots.forEach((timeSlot) => {
      result[timeSlot.id] = timeSlot;
    });
  });

  return result;
}

export function getKeyedProductVariants(products: Product[]) {
  const result: { [productVariantId: string]: ProductVariant } = {};

  products.forEach((product) => {
    product.active_product_variants.forEach((productVariant) => {
      result[productVariant.id] = productVariant;
    });
  });

  return result;
}

export const getTicketsForSale = (event: Event) => event.ticket_categories.reduce(
  (tickets, ticketCategory) => [...tickets, ...ticketCategory.tickets_for_sale],
  [] as Ticket[],
);

const filterProductPromotionsForSale = (product: Product, selectedPromotionIds: string[]) => ({
  ...product,
  promotions_for_sale: product.promotions_for_sale.filter((promotionForSale) => (
    promotionForSale.required_promotion_ids.length === 0
      || intersection(selectedPromotionIds, promotionForSale.required_promotion_ids).length > 0
  )),
});

export const getProductsForTicketPromotion = (promotion: { id: string; }, products: Product[]) => (
  products.map((product) => filterProductPromotionsForSale(product, [promotion.id]))
    .filter((product) => !product.stand_alone && product.promotions_for_sale.length > 0)
);

/**
 * Returns the list of non ticket fee products that are available with the given ticket promotion.
 */
export const getVisibleProductsForTicketPromotion = (promotion: { id: string; }, products: Product[]) => (
  getVisibleProducts(getProductsForTicketPromotion(promotion, products))
);

/**
 * Returns the list of non ticket fee products.
 */
export const getVisibleProducts = (products: Product[]) => (
  products.filter((product) => !product.is_ticket_fee)
);

/**
 * Returns the list of stand-alone products.
 */
export const getStandAloneProducts = (
  selectedTicketPromotions: { id: string }[],
  selectedProducts: { id: string }[],
  products: Product[],
) => {
  const selectedTicketPromotionIds = selectedTicketPromotions.map(({ id }) => id);
  const selectedProductIds = selectedProducts.map(({ id }) => id);

  return products.map((product) => filterProductPromotionsForSale(product, selectedTicketPromotionIds))
    .filter((product) => {
      const requiredProductIds = product.products.map((product) => product.id);

      return (
        product.stand_alone
        && product.promotions_for_sale.length > 0
        && (requiredProductIds.length === 0 || intersection(requiredProductIds, selectedProductIds).length > 0)
      );
    });
};

/**
 * Creates upgrades for ticket fee products, if any.
 */
export const getTicketFees = (ticketPromotion: Promotion, products: Product[]) => {
  if (ticketPromotion.amount === 0) {
    // Ticket fees are not applied to free tickets
    return [];
  }

  const applicableProducts = getProductsForTicketPromotion(ticketPromotion, products);

  return applicableProducts.reduce((upgrades, product) => {
    if (product.is_ticket_fee) {
      return [
        ...upgrades,
        ...times(product.min_per_ticket, () => ({
          product: { id: product.id },
          product_variant: product.active_product_variants.length > 0
            ? { id: product.active_product_variants[0].id }
            : null,
          purchase: { promotion: { id: product.promotions_for_sale[0].id } },
          fields: [],
        })),
      ] as CreateUpgradeInput[];
    }

    return upgrades;
  }, [] as CreateUpgradeInput[]);
};

interface TicketWithTeamFlags {
  allow_individuals: boolean;
  allow_create_team: boolean;
  allow_join_team: boolean;
}

export const getTeamFlags = (tickets: TicketWithTeamFlags[]) => {
  let allowIndividuals = true;
  let allowCreateTeam = false;
  let allowJoinTeam = false;
  let disallowJoinTeam = false;

  tickets.forEach((ticket) => {
    if (!ticket.allow_individuals) {
      allowIndividuals = false;
    }

    if (ticket.allow_create_team) {
      allowCreateTeam = true;

      if (!ticket.allow_join_team) {
        disallowJoinTeam = true;
      }
    }

    if (ticket.allow_join_team) {
      allowJoinTeam = true;
    }
  });

  allowJoinTeam = !disallowJoinTeam && allowJoinTeam;

  return { allowIndividuals, allowCreateTeam, allowJoinTeam };
};

/**
 * Calculates the set required participant attributes based on:
 * - Age restrictions on tickets
 * - Participant fields associated with the registrations
 * - Attributes that are enabled in the backend
 */
export const getRequiredParticipantAttributes = (
  registration: CreateRegistrationInput,
  event: Event,
) => {
  const ticket = getTicketsForSale(event).filter((ticket) => ticket.id === registration.ticket.id)[0];
  const hasTicketWithGenderRestriction = ticket?.restrictions.filter((restriction) => restriction.gender).length > 0;
  const hasTicketWithAgeRestriction = ticket?.restrictions.filter(
    (restriction) => restriction.min_age || restriction.max_age,
  ).length > 0;

  const keyedFields = keyBy(event.enabled_participant_fields, 'id');

  // Participant attributes that are required by participant fields of personal purchases.
  let requiredParticipantFieldAttributes: ParticipantAttributes[] = [];
  const fields: ParticipantField[] = [];

  registration.fields.forEach((fieldEntry) => {
    const field = keyedFields[fieldEntry.participant_field.id];

    if (field) {
      // Check if the field exists because they are loaded asynchronously
      fields.push(field);
    }
  });

  registration.upgrades.forEach((upgrade) => {
    upgrade.fields.forEach((fieldEntry) => {
      const field = keyedFields[fieldEntry.participant_field.id];

      if (field) {
        // Check if the field exists because they are loaded asynchronously
        fields.push(field);
      }
    });
  });

  fields.forEach((field) => {
    requiredParticipantFieldAttributes = [
      ...requiredParticipantFieldAttributes,
      ...field.required_participant_attributes,
    ];
  });

  return intersection(sortedParticipantAttributes, [
    ...event.participant_attributes,
    ...requiredParticipantFieldAttributes,
    ...hasTicketWithAgeRestriction ? [ParticipantAttributes.date_of_birth] : [],
    ...hasTicketWithGenderRestriction ? [ParticipantAttributes.gender] : [],
  ]);
};

export default undefined;
